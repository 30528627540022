import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlogItem from '../components/blog-item'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    return (
      <Layout>
        <SEO
          keywords={[tag, `blog`, `tech blog`, `code blog`, `blog topics`]}
          title={`${tag} | Topics`}
        />
        <div className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <h1 className="mt-2 mb-4 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              {tagHeader}
            </h1>
            <div>
              <p className="prose mb-4">
                <Link to="/topics/">Browse all topics</Link>
              </p>
              {posts &&
                posts.map(({ node: post }) => (
                  <div key={post.id}>
                    <BlogItem post={post} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMM DD, YYYY")
            tags
          }
          timeToRead
        }
      }
    }
  }
`
